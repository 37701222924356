import React, { ReactElement, memo, useState } from 'react'
import { Helmet } from 'react-helmet'

export type JsonDataTypes = {
  description?: string
  '@type'?: 'Website' | 'Article' | 'Profile' | 'Apartment'
  url?: string
  author?: {
    '@type': 'Person'
    name: string
    image?: string
    url?: string
  }
  keywords?: string
  headline?: string
  datePublished?: string
  dateModified?: string
  image?: string
  publisher?: {
    '@type': 'Organization'
    name: string
    logo: {
      '@type': `ImageObject`
      url: string
      width: 60
      height: 60
    }
  }
  numberOfRooms?: number
  occupancy?: {
    '@type': 'QuantitativeValue'
    minValue: number
    maxValue: number
  }
  floorLevel?: number
  floorSize?: {
    '@type': 'QuantitativeValue'
    value: number
    unitCode: 'MTK'
  }
  numberOfBathroomsTotal?: number
  numberOfBedrooms?: number
  petsAllowed?: boolean
  tourBookingPage?: string
  yearBuilt?: number
  telephone?: string
  address?: {
    '@type': 'PostalAddress'
    addressCountry: string
    addressLocality: string
    addressRegion: string
    postalCode: string
    streetAddress: string
  }
  latitude?: number
  longitude?: number
  mainEntityOfPage?: {
    '@type': 'WebPage'
    '@id': string
  }
}

type SeoPropTypes = {
  jsonData?: JsonDataTypes
  title?: string
  ogMetaData?: {
    title?: string
    description?: string
    image?: string
    type?: string
    url?: string
  }
  twitterMetaData?: {
    title: string
    description: string
    image: string
  }
}

const Seo = ({
  jsonData,
  title,
  ogMetaData,
  twitterMetaData,
}: SeoPropTypes): ReactElement => {
  const jsonLd = {
    '@context': `https://schema.org/`,
    '@type': `Website`,
    description: process.env.GATSBY_SITE_DESCRIPTION,
    url: process.env.GATSBY_SITE_URL,
    image: '',
    publisher:
      jsonData && jsonData['@type'] === 'Article'
        ? {
            '@type': `Organization`,
            name: process.env.GATSBY_SITE_TITLE,
            logo: {
              '@type': `ImageObject`,
              url: '',
              width: 60,
              height: 60,
            },
          }
        : undefined,
    ...jsonData,
  }

  const publicTags = jsonLd.keywords?.split(', ') || []
  const primaryTag = publicTags[0]

  const [currentUrl] = useState(ogMetaData?.url)
  return (
    <>
      <Helmet htmlAttributes={{ lang: 'en' }}>
        <title>
          {ogMetaData?.title || title || process.env.GATSBY_SITE_TITLE}
        </title>
        <meta
          name="description"
          content={ogMetaData?.description || jsonLd.description}
        />
        {jsonLd.keywords && <meta name="keywords" content={jsonLd.keywords} />}
        <link rel="canonical" href={currentUrl} />
        <meta
          name="og:site_name"
          content={ogMetaData?.title || title || process.env.GATSBY_SITE_TITLE}
        />
        <meta property="og:type" content={ogMetaData?.type} />
        <meta
          name="og:title"
          content={ogMetaData?.title || title || process.env.GATSBY_SITE_TITLE}
        />
        {Boolean(ogMetaData?.image || jsonLd.image) && (
          <meta name="og:image" content={ogMetaData?.image || jsonLd.image} />
        )}
        {Boolean(ogMetaData?.image || jsonLd.image) && (
          <meta
            property="og:image:url"
            content={ogMetaData?.image || jsonLd.image}
          />
        )}
        <meta
          name="og:description"
          content={ogMetaData?.description || jsonLd.description}
        />
        <meta name="og:url" content={currentUrl} />
        <meta
          name="twitter:title"
          content={
            twitterMetaData?.title ||
            title ||
            ogMetaData?.title ||
            process.env.GATSBY_SITE_TITLE
          }
        />
        <meta
          name="twitter:description"
          content={
            twitterMetaData?.description ||
            ogMetaData?.description ||
            jsonLd.description
          }
        />
        <meta name="twitter:url" content={currentUrl} />
        {Boolean(process.env.GATSBY_SITE_TWITTER) && (
          <meta name="twitter:site" content={process.env.GATSBY_SITE_TWITTER} />
        )}
        {Boolean(jsonLd.datePublished) && (
          <meta
            property="article:published_time"
            content={jsonLd.datePublished}
          />
        )}
        {Boolean(jsonLd.dateModified) && (
          <meta
            property="article:modified_time"
            content={jsonLd.dateModified}
          />
        )}
        {jsonData &&
          jsonData['@type'] === 'Article' &&
          publicTags.map((keyword) => (
            <meta
              property="article:tag"
              content={keyword}
              key={`tag::${keyword}`}
            />
          ))}
        {Boolean(jsonLd.author?.url) && (
          <meta property="article:author" content={jsonLd.author?.url} />
        )}
        <script type="application/ld+json">
          {JSON.stringify(jsonLd, undefined, 4)}
        </script>
      </Helmet>
      {Boolean(jsonLd.author?.name) && (
        <Helmet>
          <meta name="twitter:label1" content="Written by" />
          <meta name="twitter:data1" content={jsonLd.author?.name} />
        </Helmet>
      )}
      {Boolean(primaryTag) && (
        <Helmet>
          <meta name="twitter:label2" content="Filed under" />
          <meta name="twitter:data2" content={primaryTag} />
        </Helmet>
      )}
      {Boolean(twitterMetaData?.image || jsonLd.image) && (
        <Helmet>
          <meta name="twitter:card" content="summary_large_image" />
          <meta
            name="twitter:image"
            content={twitterMetaData?.image || jsonLd.image}
          />
        </Helmet>
      )}
    </>
  )
}

export default memo(Seo)
