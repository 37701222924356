import React, { ReactElement, useState, useEffect } from 'react'
import './Location.scss'
import { Link, graphql } from 'gatsby'
import placeholder from '../../assets/images/location-images/location-placeholder.png'
import { PropertyDataTypes } from '../Amenities/Amenities'
import Seo from '../../components/Seo/Seo'
import { LazyLoadImage } from 'react-lazy-load-image-component'

import loadable from '@loadable/component'
const Layout = loadable(() => import('../../components/Layout/Layout'))
const SearchSelect = loadable(() =>
  import('../../components/SearchSelect/SearchSelect'),
)

type LocationPropTypes = {
  data: {
    allProperty: { nodes: PropertyDataTypes[] }
  }
}

const Location = ({
  data: {
    allProperty: { nodes: propertyList },
  },
}: LocationPropTypes): ReactElement => {
  const [location, setLocation] = useState('ALL')
  const [selectedLocation, setSelectedLocation] = useState<
    PropertyDataTypes[]
  >()

  const [propertyLocations] = useState(
    propertyList
      .filter((prop) => prop.towers[0]?.locationLandmarks)
      .sort((a, b) => a?.location.localeCompare(b?.location)),
  )
  const [manilaProperties] = useState(
    propertyLocations.filter(
      (prop, index, self) =>
        prop.towers[0]?.locationLandmarks?.region === 'Metro Manila' &&
        index ===
          self.findIndex(
            (t) =>
              t.towers[0]?.locationLandmarks?.locationBrief ===
              prop.towers[0]?.locationLandmarks?.locationBrief,
          ),
    ),
  )
  const [luzonProperties] = useState(
    propertyLocations.filter(
      (prop, index, self) =>
        prop.towers[0]?.locationLandmarks?.region === 'Luzon' &&
        index ===
          self.findIndex(
            (t) =>
              t.towers[0]?.locationLandmarks?.locationBrief ===
              prop.towers[0]?.locationLandmarks?.locationBrief,
          ),
    ),
  )
  const [visayasProperties] = useState(
    propertyLocations.filter(
      (prop, index, self) =>
        prop.towers[0]?.locationLandmarks?.region === 'Visayas' &&
        index ===
          self.findIndex(
            (t) =>
              t.towers[0]?.locationLandmarks?.locationBrief ===
              prop.towers[0]?.locationLandmarks?.locationBrief,
          ),
    ),
  )

  useEffect(() => {
    const selected = propertyLocations.filter(
      (prop, index, self) =>
        prop.towers[0]?.locationLandmarks?.region === location &&
        index ===
          self.findIndex(
            (t) =>
              t.towers[0]?.locationLandmarks?.locationBrief ===
              prop.towers[0]?.locationLandmarks?.locationBrief,
          ),
    )

    setSelectedLocation(selected)
  }, [location, propertyLocations])

  const locations = [
    { value: 'Metro Manila', label: 'Metro Manila' },
    { value: 'Luzon', label: 'Luzon' },
    { value: 'Visayas', label: 'Visayas' },
    { value: 'ALL', label: 'ALL' },
  ]

  const brokenImage = (event: any): void => {
    event.target.src = placeholder // eslint-disable-line no-param-reassign
  }

  const getImageUrl = (loc: any): any => {
    const imageUrl = loc.towers[0]?.locationLandmarks?.locationImages?.find(
      (url: any) => url.featured,
    )?.url
    return imageUrl || placeholder
  }

  return (
    <Layout>
      <Seo
        title="Amaia Land Project Locations"
        ogMetaData={{
          description:
            "Amaia Land's affordable residential and commercial developments are available around the Philippines, from Manila to Luzon, Visayas and Mindanao!",
        }}
      />
      <div className="location-page">
        <div className="location-page-header">
          {location !== 'ALL' ? (
            <div className="title">
              <span>{location}</span>
              <div
                className={
                  location === 'Metro Manila' ? 'selected' : 'selected visayas'
                }
              />
            </div>
          ) : (
            <div className="title" />
          )}
          <div className="selector">
            <div className="select-label">
              <span>LOCATIONS</span>
              <SearchSelect
                className="locationPage"
                setLocationValue={setLocation}
                options={locations}
                placeholder="Select Locations"
              />
            </div>
          </div>
        </div>
        {location !== 'ALL' ? (
          <div className="location-page-content">
            {selectedLocation?.map((loc) => {
              return (
                <Link
                  key={loc.slug}
                  to={`/locations/${loc.location}/guide/`}
                  state={loc}
                  className="link"
                >
                  <div className="item">
                    <LazyLoadImage
                      src={getImageUrl(loc)}
                      onError={brokenImage}
                      alt="propImage"
                    />
                    <div className="location-container">
                      <span>
                        {loc.towers[0]?.locationLandmarks?.locationBrief}
                      </span>
                    </div>
                  </div>
                </Link>
              )
            })}
          </div>
        ) : (
          <div className="allContainer">
            <div>
              <div className="allTitle">
                <span>Metro Manila</span>
                <div className="selected" />
              </div>
              <div className="location-page-content">
                {manilaProperties.map((loc, key) => {
                  return (
                    <Link
                      key={loc.slug}
                      to={`/locations/${loc.location}/guide/`}
                      state={loc}
                      className="link"
                    >
                      <div className="item">
                        <LazyLoadImage
                          src={getImageUrl(loc)}
                          onError={brokenImage}
                          alt="propImage"
                        />
                        <div className="location-container">
                          <span>
                            {loc.towers[0]?.locationLandmarks?.locationBrief}
                          </span>
                        </div>
                      </div>
                    </Link>
                  )
                })}
              </div>
            </div>
            <div>
              <div className="allTitle">
                <span>Luzon</span>
                <div className="selected visayas" />
              </div>
              <div className="location-page-content">
                {luzonProperties.map((loc, key) => {
                  return (
                    <Link
                      key={loc.slug}
                      to={`/locations/${loc.location}/guide/`}
                      state={loc}
                      className="link"
                    >
                      <div className="item">
                        <LazyLoadImage
                          src={getImageUrl(loc)}
                          onError={brokenImage}
                          alt="propImage"
                        />
                        <div className="location-container">
                          <span>
                            {loc.towers[0]?.locationLandmarks?.locationBrief}
                          </span>
                        </div>
                      </div>
                    </Link>
                  )
                })}
              </div>
            </div>
            <div>
              <div className="allTitle">
                <span>Visayas</span>
                <div className="selected visayas" />
              </div>
              <div className="location-page-content">
                {visayasProperties.map((loc, key) => {
                  return (
                    <Link
                      key={loc.slug}
                      to={`/locations/${loc.location}/guide/`}
                      state={loc}
                      className="link"
                    >
                      <div className="item">
                        <LazyLoadImage
                          src={getImageUrl(loc)}
                          onError={brokenImage}
                          alt="propImage"
                        />
                        <div className="location-container">
                          <span>
                            {loc.towers[0]?.locationLandmarks?.locationBrief}
                          </span>
                        </div>
                      </div>
                    </Link>
                  )
                })}
              </div>
            </div>
          </div>
        )}
      </div>
    </Layout>
  )
}

export default Location

export const pageQuery = graphql`
  query {
    allProperty {
      nodes {
        ...PropertyPageFields
      }
    }
  }
`
